import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { EnRoles } from 'src/interfaces/roles';
import api from '../../../services/api';
import history from '../../../services/history';
import { CLOSE_SIDEBAR_CLIENT, OPEN_SIDEBAR_CLIENT } from '../userClient/actions';
import { signFailure, signInSuccess } from './actions';

declare module 'axios' {
  export interface HeadersDefaults {
    Authorization: string;
  }
}

export function* signIn({ payload }: { payload: { email: string, password: string } }): SagaIterator {
  try {
    const { email, password } = payload

    const response = yield call(api.post, 'adm/sessions', {
      email,
      password,
    })

    localStorage.setItem('@usernameadmin:servemei', email)
    localStorage.setItem('@passwordadmin:servemei', password)

    if (response.data) {
      const { user, accessToken, refreshToken, expiresIn, role } = response.data

      if (
        role.every(
          (role: { name: string }) =>
            role.name === EnRoles.user
        )
      ) {
        throw new Error('Usuário inválido para o acesso');
      }

      localStorage.setItem('@passwordadmin:id', user._id)
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessToken", accessToken);

      yield put(signInSuccess(user, role, accessToken, refreshToken, expiresIn))

      yield put(CLOSE_SIDEBAR_CLIENT())

      message.success('Visão de admin assumida.')
    }
  } catch (error: any) {
    if (error.message === "Usuário inválido para o acesso") {
      yield put(signFailure())
      return message.error(error.message);
    }
    if (error?.response?.data?.message === "Username/Password invalido") {
      message.error('Usuário ou senha inválidos.')
    } else {
      message.error(error?.response?.data?.message || 'Falha na autenticação, verifique seus dados e tente novamente.')
    }
    yield put(signFailure())
  }
}

export function* signInUser({ payload }: { payload: { id: string } }): SagaIterator {
  try {
    const { id } = payload

    const response = yield call(api.post, 'adm/sessions/user', { id: id })

    if (response.data) {
      const { user, role, accessToken, refreshToken, expiresIn } = response.data


      // if (
      //   role.every(
      //     (role: { name: string }) =>
      //       role.name === EnRoles.admin
      //       || role.name === EnRoles.sysAdmin
      //   )
      // ) {
      //   throw new Error('Usuário inválido para o acesso');
      // }

      localStorage.setItem("refreshToken", refreshToken);
      
      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
      }

      yield put(signInSuccess(user, role, accessToken, refreshToken, expiresIn))

      yield put(OPEN_SIDEBAR_CLIENT())

      message.success(`Visão de ${user.email} assumida.`)
    }
  } catch (error: any) {
    if (error.message === "Usuário inválido para o acesso") {
      yield put(signFailure())
      return message.error(error.message);
    }

    message.error('Falha na autenticação, verifique seus dados e tente novamente.')
  }
}

import { SagaIterator } from 'redux-saga';

export function* signUp({ payload }: { payload: { email: string, password: string, confirmPassword: string } }): SagaIterator {
  try {
    const { email, password, confirmPassword } = payload

    const response = yield call(api.post, 'novo-usuario', {
      email,
      password,
      confirmPassword,
    })

    if (response.data.success) {
      history.push('/dashboard')
    }
  } catch (error: any) {
    message.error('Falha na autenticação, verifique seus dados e tente novamente.')
    yield put(signFailure())
  }
}
export function signOutAll() {
  history.push('/')
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST' as any, signIn),
  takeLatest('@auth/SIGN_UP_REQUEST' as any, signUp),
  takeLatest('@auth/SIGN_OUT' as any, signOutAll),
  takeLatest('@auth/SIGN_IN_REQUEST_USER' as any, signInUser),
])
