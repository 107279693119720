export enum EnRoles {
  superAdmin = 'super-admin',
  sysAdmin = 'sys-admin',
  admin = 'admin',
  user = 'user',
}


export interface Role {
  id: string;
  name: string;
  description: string;
  composite: boolean;
  clientRole: boolean;
  containerId: string;
}